import React, { useState, useEffect } from "react";
import profile from "../assets/images/user.png";
import background from "../assets/images/organizer_bg.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCalendarDays,
  faHourglass,
  faClock,
  faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Navbar from "../component/navbar/Navbar";
import Footer from "../component/footer/Footer";
import upcomingEventDashboardApi from "../apis/dashboard/upcomingEvent.api";

const ProfilePage = () => {
  const [activeCategory, setActiveCategory] = useState("All");

  const searchParams = new URLSearchParams(window.location.search);

  const id = searchParams.get("id");
  const [loading, setLoading] = useState(true);
  const [organizer, setOrganizer] = useState();
  const [events, setEvents] = useState();
  const [categories, setCategories] = useState();
  const [filteredEvents, setFilteredEvents] = useState();
  const [month, setMonth] = useState();

  useEffect(() => {
    upcomingEventDashboardApi.getEventOrganizerById({
      id,
      success: (res) => {
        setOrganizer(res.data.organizerInfo[0]);
        const createdDate = new Date(res.data.organizerInfo[0]?.created_at);
        const monthYear = createdDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
        });

        setMonth(monthYear);

        setEvents(res.data.data);
        const cat = [
          "All",
          ...new Set(res.data.data.map((event) => event.categoryName)),
        ];

        const filteredEvents =
          activeCategory === "All"
            ? res.data.data.flatMap((category) => category.events)
            : res.data.data.flatMap((category) =>
                category.events?.filter(
                  (event) => event.categoryName === activeCategory
                )
              );
        
        setCategories(cat);
        setFilteredEvents(filteredEvents);

        setLoading(false);
      },
      error: (err) => {},
    });
  }, []);

  useEffect(() => {
    const filtered =
      activeCategory === "All"
        ? events?.flatMap((category) => category.events)
        : events?.flatMap((category) =>
            category?.events?.filter(
              (event) => event.Category === activeCategory
            )
          );

    setFilteredEvents(filtered);
  }, [activeCategory, events]);

  function truncateDesc(desc, wordLimit) {
    const words = desc.split(" ");
    if (words?.length > wordLimit) {
      return words?.slice(0, 7).join(" ") + "...";
    }
    return desc;
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      <Navbar />
      {loading ? 
         <div>
         <div className="animate-pulse h-[400px] bg-gray-200 rounded-lg"></div> 

         <div className="py-10 px-4 space-y-6"> 
           <div className="flex flex-col md:flex-row md:items-start md:gap-10 mx-auto max-w-7xl">
             <div className="space-y-4">
               <div className="animate-pulse h-8 bg-gray-200 rounded-lg w-full"></div> 
               <div className="animate-pulse h-6 bg-gray-200 rounded-lg w-48"></div> 
             </div>
             <div className="w-full space-y-6">
               {[1, 2, 3].map((item) => ( 
                 <div key={item} className="animate-pulse space-y-2">
                   <div className="h-48 bg-gray-200 rounded-lg"></div> 
                   <div className="h-6 bg-gray-200 rounded-lg w-full"></div> 
                   <div className="h-4 bg-gray-200 rounded-lg w-3/4"></div> 
                   <div className="h-4 bg-gray-200 rounded-lg w-1/2"></div> 
                 </div>
               ))}
             </div>
             <div className="w-64 space-y-4">
               <div className="animate-pulse h-12 bg-gray-200 rounded-lg"></div> 
               <div className="animate-pulse h-6 bg-gray-200 rounded-lg w-full"></div> 
               <div className="animate-pulse h-6 bg-gray-200 rounded-lg w-3/4"></div> 
             </div>
           </div>
         </div>
       </div>
      : (
        <div className="bg-white flex flex-col container-xl mx-auto">
          <div
            className="relative bg-cover bg-center h-[400px] flex items-center justify-center"
            style={{ backgroundImage: `url('${background}')` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>
            <div className="relative z-10 w-full px-8 max-w-7xl mx-auto">
              <div className="flex flex-col md:flex-row justify-between items-center text-white">
                <div className="flex items-center space-x-4 mb-4 md:mb-0">
                  <img
                    src={organizer?.organizer_photo || profile}
                    alt="Profile Avatar"
                    className="w-20 h-20 inline-block rounded-full"
                  />
                  <div>
                    <h1 className="text-3xl font-semibold">
                      {organizer?.organizer_name}
                    </h1>
                    <p className="text-sm">Member since {month}</p>
                  </div>
                </div>
                <div className="flex space-x-4">
                  <div className="text-2xl hidden md:block">Follow me</div>
                  <a
                    href={organizer?.organizer_facebook}
                    aria-label="Follow on Facebook"
                    className=" hover:text-blue-600 p-2 px-3 bg-gray-100 bg-opacity-20 rounded-full"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href={organizer?.organizer_linkedin}
                    aria-label="Follow on LinkedIn"
                    className=" hover:text-blue-400 p-2 px-3 bg-gray-100 bg-opacity-20 rounded-full"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />
                  </a>
                  <a
                    href={organizer?.organizer_twitter}
                    aria-label="Follow on Twitter"
                    className=" hover:text-blue-500 p-2 px-3 bg-gray-100 bg-opacity-20 rounded-full"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="py-10 px-4 ">
            <div className="flex flex-col md:flex-row md:items-start md:gap-10 mx-auto max-w-7xl">
              <div>
                <h1 className="text-3xl font-bold mb-5">All Events</h1>
                <ul className="flex flex-wrap mb-8" role="tablist">
                  {categories.map((category) => (
                    <li
                      key={category}
                      className="mr-4 mb-2"
                      role="presentation"
                    >
                      <button
                        className={`px-4 py-2 rounded-full font-medium transition-colors duration-200 
                    ${
                      activeCategory === category
                        ? "bg-violet-500 text-white"
                        : "bg-gray-200 hover:bg-gray-300"
                    }`}
                        onClick={() => setActiveCategory(category)}
                        role="tab"
                        aria-selected={
                          activeCategory === category ? "true" : "false"
                        }
                        id={`tab-${category}`}
                        aria-controls={`panel-${category}`}
                      >
                        {category}
                      </button>
                    </li>
                  ))}
                </ul>

                <div
                  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6"
                  role="tabpanel"
                >
                  {filteredEvents?.map((item, index) => (
                    <div
                      key={index}
                      className="event-item relative block mb-8 bg-white rounded-[6%] overflow-hidden shadow-lg"
                      role="tabpanel"
                      aria-labelledby={`tab-${item.Category}`}
                      id={`panel-${item.Category}`}
                    >
                      <div className="event-image relative group">
                        <Link to={`/eventselection?id=${item?.event_id}`}>
                          <img
                            src={item?.thumbnail}
                            alt={item?.Event_Name}
                            className="event-image w-50 h-30 rounded-t-xl sm:h-50 object-cover object-center overflow-hidden"
                          />
                        </Link>
                        <div className="event-content relative p-5 shadow-sm">
                          <ul className="time-info absolute -top-5 left-0 right-0 w-[calc(100%-50px)] mx-auto flex items-center justify-between text-xs flex-wrap p-3 bg-white rounded-md shadow-md">
                            <li className="event-info flex items-center gap-1">
                              <FontAwesomeIcon
                                icon={faCalendarDays}
                                className="text-primary text-sm"
                              />
                              <span>{formatDate(item?.start_date)}</span>
                            </li>
                            <li className="event-info flex items-center gap-1">
                              <FontAwesomeIcon
                                icon={faHourglass}
                                className="text-primary font-light text-sm"
                              />
                              <span
                                title="Event Duration"
                                className="font-light"
                              >
                                {item?.duration || "N/A"}
                              </span>
                            </li>
                            <li className="event-info flex items-center gap-1">
                              <FontAwesomeIcon
                                icon={faClock}
                                className="text-primary text-sm"
                              />
                              <span className="font-light">
                                {item?.start_time}
                              </span>
                            </li>
                          </ul>

                          <h5 className="mt-2 line-clamp-2 min-h-[50px] text-xl text-gray-950 font-semibold">
                            <Link to={`/eventselection?id=${item?.event_id}`}>
                              {item?.Event_Name}
                            </Link>
                          </h5>
                          <p className="event-description font-light text-base  text-[#0000007a] min-h-[60px] line-clamp-2 text-gray-400">
                            {truncateDesc(item?.Event_Slug, 10)}
                          </p>
                          <div className="price-remain flex justify-between items-center text-sm mt-3 pt-3 border-t border-dashed border-gray-300">
                            <div className="location text-primary flex items-center gap-2">
                              {item?.event_type === "venue" ? (
                                <>
                                  <FontAwesomeIcon icon={faLocationDot} />
                                  <span className="line-clamp-1">
                                    {item?.City} {item?.country}
                                  </span>
                                </>
                              ) : (
                                <p className="line-clamp-1">Online-Event</p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6">
                <div className="flex items-center mb-4">
                  <img
                    src={organizer?.organizer_photo || profile}
                    alt="Organizer Profile"
                    className="w-16 h-16 rounded-full mr-4"
                  />
                  <div>
                    <h2 className="text-xl font-semibold">
                      {organizer?.organizer_name}
                    </h2>
                    <p className="text-gray-600 text-sm">
                      Member since {month}
                    </p>
                  </div>
                </div>
                <ul className="border-t border-gray-200 pt-4">
                  <li className="flex justify-between py-2">
                    <span className="font-medium">Total Events</span>
                    <span>{events.length}</span>
                  </li>
                  <li className="flex justify-between py-2">
                    <span className="font-medium mr-3">Email </span>
                    <span> {organizer?.organizer_email}</span>
                  </li>
                </ul>
                <div className="mt-6">
                  <a
                    href={`mailto:${organizer?.organizer_email}`}
                    className="block w-full bg-violet-500 text-white text-center py-3 rounded-lg hover:bg-violet-600 transition-colors duration-200"
                  >
                    Contact Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default ProfilePage;
