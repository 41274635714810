import { create } from "zustand";
import { persist } from "zustand/middleware";
import authApi from "../apis/auth.api";
import authApiOrganizer from "../apis/organiserApis/authOrganiser.api";
import authApiAdmin from "../apis/adminApis/authAdmin.api";
import basicSetting from "../apis/adminApis/basicSetting.api";

export const useStore = create(
  persist(
    (set) => ({
      userLocation: {
        lat: 17.385,
        lng: 78.4867,
      },
      setUserLocation: (lat, lng) => set({ userLocation: { lat, lng } }),
      user: {
        isAuthenticated: false,
        data: null,
        role: null,
      },
      basicSettings: null,
      EventOrderDetails: null,
      MovieOrderDetails: null,
      orderDetails: null,
      // Assuming your API endpoint can handle different user types
      verifySession: async () => {
        try {
          authApi.verifySession({
            success: (res) => {
              switch (res.data.data.roleType) {
                case "attendee":
                  set({
                    user: {
                      isAuthenticated: true,
                      data: res.data.data,
                      role: "attendee",
                    },
                  });
                  break;
                case "organizer":
                  set({
                    user: {
                      isAuthenticated: true,
                      data: res.data.data,
                      role: "organizer",
                    },
                  });
                  break;
                case "admin":
                  set({
                    user: {
                      isAuthenticated: true,
                      data: res.data.data,
                      role: "admin",
                    },
                  });
                  break;
                default:
                  // Handle unknown role or authentication failure
                  set({
                    user: { isAuthenticated: false, data: null, role: null },
                  });
              }
            },
            error: (err) => {
              set({
                user: { isAuthenticated: false, data: null, role: null },
              });
            },
          });
        } catch (err) {
          // Handle API error
          console.error("Error verifying session:", err);
          set({
            user: { isAuthenticated: false, data: null, role: null },
          });
        }
      },
      setIsAuthenticated: (data) => set({ user: { isAuthenticated: data } }),
      setUserData: (Data) => set({ user: { data: Data } }),
      setUserType: (data) => set({ user: { role: data } }),
      setBasicSettings: (data) => set({ basicSettings: data }),
      setEventOrderDetails: (data) => set({ EventOrderDetails: data }),
      setOrderDetails: (data) => set({ orderDetails: data }),
      setMovieOrderDetails: (data) => set({ MovieOrderDetails: data }),
    }),
    {
      name: "user-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export const checkSession = (userType) => {
  const { verifySession, verifySessionOrganizer } = useStore.getState();

  if (userType === "organizer") {
    verifySessionOrganizer();
  } else {
    verifySession();
  }
};

export const likedData = create(
  persist(
    (set) => ({
      likedEvents: [],
      setLikedEvents: (data) => set({ likedEvents: data }),
      updateLikedEvents: (data) =>
        set((state) => ({ likedEvents: [...state.likedEvents, data] })),
      removeLikedEvents: (data) =>
        set((state) => ({
          likedEvents: state.likedEvents.filter((item) => item.id !== data.id),
        })),
      likedMovies: [],
      setLikedMovies: (data) => set({ likedMovies: data }),
      updateLikedMovies: (data) =>
        set((state) => ({ likedMovies: [...state.likedMovies, data] })),
      removeLikedMovies: (data) =>
        set((state) => ({
          likedMovies: state.likedMovies.filter((item) => item.id !== data.id),
        })),
    }),
    {
      name: "liked-storage",
      getStorage: () => sessionStorage,
    }
  )
);

export const reviewsData = create((set) => ({
  reviews: [],
  setReviews: (data) => set({ reviews: data }),
  updateReviews: (data) =>
    set((state) => ({ reviews: [...state.reviews, data] })),
}));
