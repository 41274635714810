import axios from "axios";

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_BACKEND,
});



const getRequest = (data) => {
  const { path, config, success, error, final } = data;
  axiosInstance.get(path, config).then(success).catch(error).finally(final);
};

const postRequest = (data) => {
  const { path, payload, config, success, error, final } = data;
  axiosInstance
    .post(path, payload, config)
    .then(success)
    .catch(error)
    .finally(final);
};
const postPdfRequest = (data) => {
  const { path, payload, config = {}, success, error, final } = data;
  axiosInstance
    .post(path, payload, { ...config, headers: { Accept: "application/pdf" }}) 
    .then(success)
    .catch(error)
    .finally(final);
};
const putRequest = (data) => {
  const { path, payload, success, error, config, final } = data;
  axiosInstance
    .put(path, payload, config)
    .then(success)
    .catch(error)
    .finally(final);
};

const deleteRequest = (data) => {
  const { path, payload,success, error, final } = data;
  axiosInstance.delete(path, { data: payload }).then(success).catch(error).finally(final);
};


export default {
  getRequest,
  postRequest,
  postPdfRequest,
  putRequest,
  deleteRequest,
};
