const BASEURL = process.env.REACT_APP_BACKEND;


const setBaseUrlPrefix = (path) => {
  return BASEURL.concat(path);
};


const apiOrganizerPathConstants = {

    authOrganizer: {
        loginOrg: setBaseUrlPrefix("/auth/org/login"),
        logoutOrg: setBaseUrlPrefix("/auth/org/logout"),
        registerOrg: setBaseUrlPrefix("/auth/org/register"),
        sendEmailOrg: setBaseUrlPrefix("/auth/org/send/verification-email"),
        verifyEmailOrg: setBaseUrlPrefix("/auth/org/verify-email"),
        forgotPasswordOrg: setBaseUrlPrefix("/auth/org/forgot-password"),
        resetPasswordTokenOrg: setBaseUrlPrefix("/auth/org/reset-password"),
        verifySessionOrg: setBaseUrlPrefix("/auth/org/verify-session"),
        updateProfileOrg: setBaseUrlPrefix("/auth/org/organizer"),
    },

    dashBoardOrganizer: {
      getOrganizerDashboard: setBaseUrlPrefix("/org/orgDashboard"),
    },

    supportTicketOrg: {
      getTicketListOrg: setBaseUrlPrefix("/support/ticketsListOrg"),
      addTicketOrg: setBaseUrlPrefix("/support/addTicketOrg"),
    },

    bookingOrg:{
      getOrganizerBooking: setBaseUrlPrefix("/bookings/allBookingOrganizer"),
      getReportOrganizer : setBaseUrlPrefix("/bookings/bookingReportOrg"),
    },
  };
  
  export default apiOrganizerPathConstants;