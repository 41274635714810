import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';


const container = document.getElementById('root');

if (container) {
  const root = createRoot(container); 
  root.render(
    
      <App />
    
  );
} else {
  console.error("Container element with ID 'root' not found.");
}

