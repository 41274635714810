import { Fragment, useState, useEffect } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

import { Link, Navigate } from "react-router-dom";
import { CiUser, CiCalendar } from "react-icons/ci";
import { useStore } from "../../utils/store";
import { likedData } from "../../utils/store";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RiAdminLine } from "react-icons/ri";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import authApi from "../../apis/auth.api";
import { toast } from "react-toastify";
import authApiAdmin from "../../apis/adminApis/authAdmin.api";
import authApiOrganiser from "../../apis/organiserApis/authOrganiser.api";
const navigation = [
  { name: "Home", href: "/", current: false },
 
  { name: "Events", href: "/eventslist", current: false },

  { name: "Movies", href: "/movieslist", current: false },
  { name: "Pricing", href: "/Pricing", current: false },

  { name: "Contact", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const {
    user: { isAuthenticated, role, data },
    setIsAuthenticated,
    setUserType,
    setUserData,
    basicSettings,
    verifySession
  } = useStore();
  const setLikedEvents = likedData((state) => state.setLikedEvents);
  const setLikedMovies = likedData((state) => state.setLikedMovies);
  const location = useLocation();

  const [dashBoardLink, setDashBoardLink] = useState(null);
  useEffect(() => {
    
    if (role === "attendee") {
      setDashBoardLink("/dashboard");
    }
    else if (role === "organizer") {
      
      setDashBoardLink("/organizer");
    }
   else  if (role === "admin") {
      setDashBoardLink("/admin");
    }
  }, [role,data,isAuthenticated]);
 

  useEffect(() => {
    const fetchData = async () => {
      await verifySession(); 
    };
  
    fetchData();
  }, []); 


  const profilePhotoSrc = data?.photo ? data?.photo : null;

  const handleLogout = async () => {
    if(role==="attendee"){
    try {
      await authApi.handleLogout({
        success: async(res) => {
          setIsAuthenticated(false);
          setUserData(null)
          setUserType(null)
          setLikedEvents(null);
          setLikedMovies(null);

          toast.success("Logout successfully...", {
            position: "top-center",
            autoClose: 2000,
          });
          
        },
        error: (err) => {
          
        },
      });
      navigate("/");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }
  if(role==="organizer"){
    authApiOrganiser.handleLogoutOrg({
      success: async (res) => {
        setUserType(null)
        setIsAuthenticated(false);
        setUserData(null)
        toast.success("Logout successfully...", {
          position: "top-center",
          autoClose: 2000,
        });
        
        navigate("/");
      },
      error: (err) => {
        toast.error("Error while logout organizer...", {
          position: "top-center",
          autoClose: 2000,
        });
        
      },
    })
  }
  if(role==="admin"){
    authApiAdmin.handleLogoutAdmin({
      success: async (res) => {
        setIsAuthenticated(false);
        setUserData(null);
        setUserType(null)
        setUserType(null)
       
        
        toast.success("Logout successfully...", {
          position: "top-center",
          autoClose: 2000,
        });
        
        navigate("/");
      },
      error: (err) => {
        toast.error("Error while logout admin...", {
          position: "top-center",
          autoClose: 2000,
        });
        
      },
    });
  }
  };

  return (
    <Disclosure as="nav" className={`fixed w-full z-20 bg-primary`}>
      {({ open }) => (
        <>
          <div className="mx-auto lg:max-w-8xl px-2 sm:px-6 lg:px-8 z-20">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-between lg:items-stretch lg:justify-start">
                <Link to="/" className="flex flex-shrink-0 items-center">
                  <img
                    className="w-44"
                    src={basicSettings?.logo}
                    alt={basicSettings?.website_title}
                  />
                </Link>

                <div className="hidden lg:flex lg:ml-6 lg:flex-1 lg:justify-center">
                  <div className="flex space-x-4 justify-center items-center mt-2 ">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-3 sm:pr-0">
                  {!data  ? (
                    <div className="hidden lg:flex justify-evenly items-center">
                      <a
                        href="registerOrganizer"
                        className="text-white text-center w-19 flex self-center text-sm font-bold shadow-xl bg-pink-600 justify-center items-center px-2 py-2 rounded-full max-md:px-5 max-sm:w-52"
                      >
                        Create Events
                      </a>

                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="relative flex  text-sm ">
                            <button
                              className={`text-white ml-4 border border-spacing-1 p-2 px-5 rounded-full }`}
                            >
                              Login
                            </button>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/login"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    " px-4 py-2 text-sm text-gray-700 flex items-center gap-2"
                                  )}
                                >
                                  <CiUser size={15} />
                                  Attendee
                                </a>
                              )}
                            </Menu.Item>

                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  // href="https://event-management-admin-wolfertech.netlify.app"
                                  href="/organizerLogin"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "flex items-center gap-2 px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  <CiCalendar size={15} />
                                  Organizer
                                </a>
                              )}
                            </Menu.Item>

                           
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  ) : (
                    <Menu as="div" className="relative ml-3">
                      <div className="flex text-center items-center text-white space-x-2">
                        <div className="hidden sm:block">
                          <p>Hello, {data?.username} </p>
                        </div>
                        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          {data?.photo ? (
                            <img
                              className="h-8 w-8 rounded-full"
                              src={data?.photo}
                               loading="lazy"
                              alt={`${
                                data.first_name || "User"
                              }'s profile picture`}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faUser}
                              className="h-8 w-8 text-white"
                            />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  navigate(dashBoardLink);
                                }}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Your Profile
                              </button>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleLogout}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                type="button"
                              >
                                Logout
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}

              <div>
                {!data ? (
                  <div className="flex flex-col items-center mt-4">
                    <a
                      href="registerOrganizer"
                      className="text-white text-center w-19 flex self-center text-sm font-bold shadow-xl bg-pink-600 justify-center items-center px-2 py-2 rounded-full max-md:px-5 max-sm:w-52"
                    >
                      Create Events
                    </a>

                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="relative flex mt-2 text-sm ">
                          <button
                            className={`text-white ml-4 border border-spacing-1 p-2 px-5 rounded-full }`}
                          >
                            Login
                          </button>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/login"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  " px-4 py-2 text-sm text-gray-700 flex items-center gap-2"
                                )}
                              >
                                <CiUser size={15} />
                                Attendee
                              </a>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/organizerLogin"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "flex items-center gap-2 px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                <CiCalendar size={15} />
                                Organizer
                              </a>
                            )}
                          </Menu.Item>

                          
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <>
                    <Menu as="div" className="relative ml-3">
                      <div className="flex text-center items-center text-white space-x-2">
                        <div className="hidden sm:block">
                          <p>Hello, {data?.username} </p>
                        </div>
                        <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          {data?.photo ? (
                            <img
                              className="h-8 w-8 rounded-full"
                              src={data?.photo}
                              
                              alt={`${
                                data?.first_name || "User"
                              }'s profile picture`}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faUser}
                              className="h-8 w-8 text-white"
                            />
                          )}
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  navigate(dashBoardLink);
                                }}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Your Profile
                              </button>
                            )}
                          </Menu.Item>

                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={handleLogout}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                                type="button"
                              >
                                Logout
                              </button>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </>
                )}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
