import apiPath from "../constants/api-path.constant";
import axios from "../configs/axios-instances";

const additionalApi = {
  getLanguageList: ({ payload, success, error }) => {
    const {
      language: { getLanguageList },
    } = apiPath;

    axios.getRequest({ path: getLanguageList, success, error });
  },
  getEventCouponList: ({ payload, success, error }) => {
    const {
      coupons: { getEventCoupon },
    } = apiPath;

    axios.postRequest({ path: getEventCoupon, payload, success, error });
  },
  addLanguage: ({ payload, success, error }) => {
    const {
      language: { addLanguage },
    } = apiPath;

    axios.postRequest({ path: addLanguage, payload, success, error });
  },
  updateLanguage: ({ id, payload, success, error }) => {
    const {
      language: { updateLanguage },
    } = apiPath;

    axios.putRequest({
      path: `${updateLanguage}?id=${id}`,
      payload,
      success,
      error,
    });
  },
  deleteLanguage: ({ id, payload, success, error }) => {
    const {
      language: { deleteLanguage },
    } = apiPath;

    axios.deleteRequest({
      path: `${deleteLanguage}?id=${id}`,
      payload,
      success,
      error,
    });
  },

  addPageHeadings: ({ payload, success, error }) => {
    const {
      pageHeadings: { addPageHeadings },
    } = apiPath;

    axios.postRequest({ path: addPageHeadings, payload, success, error });
  },
  getPageHeadingsList: ({ payload, success, error }) => {
    const {
      pageHeadings: { getPageHeadings },
    } = apiPath;

    axios.getRequest({ path: getPageHeadings, success, error });
  },
  updatePageHeading: ({ id, payload, success, error }) => {
    const {
      pageHeadings: { updatePageHeadings },
    } = apiPath;

    axios.putRequest({
      path: `${updatePageHeadings}`,
      payload,
      success,
      error,
    });
  },
  getOrgTransactions: ({ payload, success, error }) => {
    const {
      transactions: { getOrgTransactions },
    } = apiPath;

    axios.postRequest({ path: getOrgTransactions, payload, success, error });
  },

  getAdminTransactions: ({ payload, success, error }) => {
    const {
      transactions: { getAdminTransactions },
    } = apiPath;

    axios.postRequest({ path: getAdminTransactions, payload, success, error });
  },
  connectAccount: ({ payload, success, error }) => {
    const {
      withDrawal: { connectAccount },
    } = apiPath;

    axios.postRequest({ path: connectAccount, success, error });
  },
  checkStatus: ({ payload, success, error }) => {
    const {
      withDrawal: { checkStatus },
    } = apiPath;

    axios.postRequest({ path: checkStatus, payload, success, error });
  },
  requestWithdrawal: ({ payload, success, error }) => {
    const {
      withDrawal: { requestWithdrawal },
    } = apiPath;

    axios.postRequest({ path: requestWithdrawal, payload, success, error });
  },
  withdrawalList: ({ payload, success, error }) => {
    const {
      withDrawal: { withdrawalList },
    } = apiPath;

    axios.postRequest({ path: withdrawalList, payload, success, error });
  },
  updateWithdrawal: ({ payload, success, error }) => {
    const {
      withDrawal: { updateStatus },
    } = apiPath;

    axios.postRequest({ path: updateStatus, payload, success, error });
  },
  mailToAdmin: ({ payload, success, error }) => {
    const {
      contactUs: { mailToAdmin },
    } = apiPath;

    axios.postRequest({ path: mailToAdmin, payload, success, error });
  },
  addSubscriber: ({ payload, success, error }) => {
    const {
      contactUs: { addSubscriber },
    } = apiPath;

    axios.postRequest({ path: addSubscriber, payload, success, error });
  },
};

export default additionalApi;
